import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  label: string;
  value: string | number | null;
};

export const StatisticItem = ({ value, label }: Props) => {
  const { t } = useTranslation();

  return (
    <Grid
      item
      xs={6}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        gap: '4px',
      }}
    >
      <Typography variant="body1">{t(label)}:</Typography>
      <Typography variant="body1">{value || '--'}</Typography>
    </Grid>
  );
};
