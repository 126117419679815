import {
  BookingStatuses,
  BookingStatusGroups,
  mapNewToOldStatuses,
  Statuses,
} from 'types/reservation';
import { bookingGroups } from 'app/components/NewBookingModal/_config';
import { Box, ButtonBase, Typography, SelectChangeEvent } from '@mui/material';
import { getStatusGroupColor } from 'utils/data-processors/getStatusColors';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { CustomSelect } from 'app/components/FormElements/CustomSelect';
import { useScreenSize } from 'hooks/useScreenSize';

interface OwnProps {
  setBookingStatus: (status: Statuses) => void;
  isNewReservation: boolean;
  setSelectedStatusGroup: (group_statuses: BookingStatuses[]) => void;
  selectedGroupName: BookingStatusGroups;
  setSelectedGroupName: (groupName: BookingStatusGroups) => void;
}

export const BookingGroups = ({
  setBookingStatus,
  isNewReservation,
  setSelectedStatusGroup,
  selectedGroupName,
  setSelectedGroupName,
}: OwnProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { isMobile } = useScreenSize();

  const setGroupNameAndGroupStatuses = (
    groupName: BookingStatusGroups,
    group_statuses: BookingStatuses[],
  ) => {
    setSelectedGroupName(groupName);
    setSelectedStatusGroup(group_statuses);
    const firstStatus = mapNewToOldStatuses[group_statuses[0]];
    setBookingStatus(firstStatus);
  };

  const bookingGroupsOptions = bookingGroups.map(
    ({ name, group_name, group_statuses, inactive, icon }) => {
      const isBlocked = !isNewReservation && group_name === BookingStatusGroups.Blocked;
      const disabled = isBlocked || inactive;

      const colors = getStatusGroupColor(group_name);

      return {
        icon,
        label: t(name),
        value: group_name,
        disabled: disabled,
        data: group_statuses,
        colors: {
          menuItemColor: colors.firstColor,
          firstColor: 'brandWhite',
          secondColor: colors.firstColor,
        },
      };
    },
  );

  const handleGroupNameAndStatusesChange = (event: SelectChangeEvent<HTMLInputElement>) => {
    const groupName = event.target.value as BookingStatusGroups;
    const groupOption = bookingGroupsOptions.find((option) => option.value === groupName);
    if (groupOption) {
      setGroupNameAndGroupStatuses(groupName, groupOption.data);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        gap: theme.customSpacing.bookingModal,
        flexDirection: { xs: 'row', md: 'column' },
        flexWrap: 'wrap',
      }}
    >
      {isMobile ? (
        <CustomSelect
          options={bookingGroupsOptions}
          value={selectedGroupName}
          onChange={handleGroupNameAndStatusesChange}
          sx={{
            maxWidth: 'fit-content',
          }}
        />
      ) : (
        <>
          {bookingGroups.map(({ name, group_name, group_statuses, inactive, icon }, index) => {
            const isBlocked = !isNewReservation && group_name === BookingStatusGroups.Blocked;
            const disabled = isBlocked || inactive;
            return (
              <ButtonBase
                key={index}
                onClick={() => setGroupNameAndGroupStatuses(group_name, group_statuses)}
                disabled={disabled}
                sx={{
                  transition: 'background-color 0.25s ease',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor:
                    selectedGroupName === group_name
                      ? getStatusGroupColor(group_name).firstColor
                      : 'brandWhite',
                  width: '64px',
                  height: '64px',
                  borderRadius: theme.borderRadius.small,
                  color: getStatusGroupColor(group_name).firstColor,
                  gap: '4px',
                  '& svg': {
                    transition: 'fill 0.25s ease',
                  },
                  '& svg path': {
                    fill:
                      selectedGroupName === group_name
                        ? 'white'
                        : getStatusGroupColor(group_name).firstColor,
                  },
                  '&:hover': {
                    backgroundColor:
                      selectedGroupName === group_name
                        ? getStatusGroupColor(group_name).firstColor
                        : getStatusGroupColor(group_name).secondColor,
                  },
                  ...(disabled && {
                    opacity: 0.5,
                  }),
                }}
              >
                {icon}
                <Typography
                  sx={{
                    fontSize: '10px',
                    fontWeight: 600,
                    textAlign: 'center',
                    maxWidth: '52px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    color:
                      selectedGroupName === group_name
                        ? 'white'
                        : getStatusGroupColor(group_name).firstColor,
                  }}
                >
                  {t(name)}
                </Typography>
              </ButtonBase>
            );
          })}
        </>
      )}
    </Box>
  );
};
