import moment from 'moment/moment';
import { geChannelIcon } from 'app/components/NewSidebar/_components/SidebarStatistics/_config';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'redux/hooks';

export const ShortBookingInfo = () => {
  const { guests, created_at, id, origin } = useAppSelector((state) => state.app.booking);

  const { t } = useTranslation();

  const guestText = guests > 0 ? `${guests} ${guests === 1 ? t('person') : t('persons')}` : '';
  const dateText = created_at ? `${moment.unix(created_at).format('MMMM D')}` : '';
  const timeText = created_at ? `${moment.unix(created_at).format('HH:mm')}` : '';
  const idText = id ? `#${id}` : '';

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 0.5,
        flexWrap: 'wrap',
      }}
    >
      {[guestText, dateText, timeText, idText]
        .filter((text) => text)
        .join(' · ')
        .trim()}
      <> · </>
      {geChannelIcon(origin)}
    </Box>
  );
};
