import { Typography } from '@mui/material';

type Props = {
  color?: string;
  label: string;
};

export const TurnoverLabel = ({ color, label }: Props) => {
  return (
    <Typography
      variant="body2"
      sx={{
        color: color || 'secondary.main',
        border: '1px solid',
        borderColor: color || 'secondary.main',
        borderRadius: 4,
        padding: 0.5,
        width: '65px',
        textAlign: 'center',
      }}
    >
      {label}
    </Typography>
  );
};
