import {
  BookingStatuses,
  bookingStatusGroups,
  BookingStatusGroups,
  Statuses,
} from 'types/reservation';
import { ReactComponent as ConfirmedIcon } from 'images/BookingStatuses/ic-confirmed-status.svg';
import { ReactComponent as SeatedIcon } from 'images/BookingStatuses/ic-seated-status.svg';
import { ReactComponent as WalkinIcon } from 'images/BookingStatuses/ic-walkin-status.svg';
import { ReactComponent as PendingIcon } from 'images/BookingStatuses/ic-pending-status.svg';
import { ReactComponent as BlockedIcon } from 'images/BookingStatuses/ic-blocked-status.svg';
import { ReactComponent as NoShowIcon } from 'images/BookingStatuses/ic-no-show-status.svg';
import { ReactComponent as CancelIcon } from 'images/BookingStatuses/ic-cancel-status.svg';
import { ReactComponent as WaitingListIcon } from 'images/BookingStatuses/ic-waiting-list-status.svg';
import { ReactComponent as EventIcon } from 'images/BookingStatuses/ic-event.svg';
import { ReactComponent as TemplateIcon } from 'images/BookingStatuses/ic-template.svg';
import * as yup from 'yup';
import phoneNumberIsValid from 'utils/validation/phoneNumber';
import i18n from 'i18n';

type StatusConfig = {
  [key in Statuses]?: {
    showGuestDetails: boolean;
    showReservationTags: boolean;
    showExperiences: boolean;
    showFilesAndExtraInformation: boolean;
    showAddGuestDetails: boolean;
    showComments: boolean;
    showGuestNote: boolean;
  };
};

const statusConfig: StatusConfig = {
  [Statuses.Blocked]: {
    showGuestDetails: false,
    showReservationTags: false,
    showExperiences: false,
    showFilesAndExtraInformation: false,
    showAddGuestDetails: true,
    showComments: true,
    showGuestNote: false,
  },
  [Statuses.WalkIn]: {
    showGuestDetails: false,
    showReservationTags: false,
    showExperiences: false,
    showFilesAndExtraInformation: false,
    showAddGuestDetails: true,
    showComments: false,
    showGuestNote: false,
  },
};

const bookingModalConfig = (selectedStatus: Statuses) => {
  return (
    statusConfig[selectedStatus] || {
      showGuestDetails: true,
      showReservationTags: true,
      showExperiences: true,
      showFilesAndExtraInformation: true,
      showAddGuestDetails: false,
      showComments: true,
      showGuestNote: true,
    }
  );
};

const bookingStatuses = [
  { name: 'bookingStatusConfirmed', status: Statuses.Confirmed, icon: <ConfirmedIcon /> },
  { name: 'bookingStatusArrived', status: Statuses.Arrived, icon: <SeatedIcon /> },
  { name: 'bookingStatusWalkIn', status: Statuses.WalkIn, icon: <WalkinIcon /> },
  { name: 'bookingStatusPending', status: Statuses.Pending, icon: <PendingIcon /> },
  {
    name: 'bookingStatusWaitingList',
    status: Statuses.WaitingList,
    icon: <WaitingListIcon />,
  },
  { name: 'bookingStatusBlocked', status: Statuses.Blocked, icon: <BlockedIcon /> },
  { name: 'bookingStatusNoShow', status: Statuses.NoShow, icon: <NoShowIcon /> },
  { name: 'bookingStatusCancelled', status: Statuses.Cancelled, icon: <CancelIcon /> },
];

const bookingGroups = [
  {
    name: 'bookingStatusGroupReservation',
    group_name: BookingStatusGroups.Reservation,
    group_statuses: bookingStatusGroups[BookingStatusGroups.Reservation],
    icon: <ConfirmedIcon />,
  },
  {
    name: 'bookingStatusGroupWalkIn',
    group_name: BookingStatusGroups.WalkIn,
    group_statuses: bookingStatusGroups[BookingStatusGroups.WalkIn],
    icon: <WalkinIcon />,
  },
  {
    name: 'bookingStatusGroupEvent',
    group_name: BookingStatusGroups.Event,
    group_statuses: bookingStatusGroups[BookingStatusGroups.Event],
    icon: <EventIcon />,
    inactive: true,
  },
  {
    name: 'bookingStatusGroupBlocked',
    group_name: BookingStatusGroups.Blocked,
    group_statuses: bookingStatusGroups[BookingStatusGroups.Blocked],
    icon: <BlockedIcon />,
  },
  {
    name: 'bookingStatusGroupWaitingList',
    group_name: BookingStatusGroups.WaitingList,
    group_statuses: bookingStatusGroups[BookingStatusGroups.WaitingList],
    icon: <WaitingListIcon />,
  },
  {
    name: 'bookingStatusGroupTemplate',
    group_name: BookingStatusGroups.Template,
    group_statuses: bookingStatusGroups[BookingStatusGroups.Template],
    icon: <TemplateIcon />,
    inactive: true,
  },
];

const bookingFormFieldNames: any = {
  status: 'status',
  message: 'important_message',
  date: 'date',
  guests: 'guests',
  start_time: 'start_time',
  end_time: 'end_time',
  duration: 'duration',
  tables: 'tables',
  waiter_id: 'waiter_id',
  lock_table: 'lock_table',
  tags: 'tags',
  comments: 'comments',
  special_offers: 'special_offer.id',
  files: 'files',
  client: {
    note: 'client.note',
    search: 'client.search',
    name: 'client.name',
    phone: 'client.phone',
    email: 'client.email',
    company: 'client.company',
    subscribed: 'client.subscribed',
    tags: 'client.tags',
    additional_info: {
      short_info: 'client.additional_info.short_info',
      birthday: 'client.additional_info.birthday',
      family_member: 'client.additional_info.family_member',
      favourites: 'client.additional_info.favourites',
      description: 'client.additional_info.description',
    },
  },
  notifications: {
    language: 'notifications.language',
    sms: 'notifications.sms',
    email: 'notifications.email',
  },
  late_cancellation: {
    currency: 'late_cancellation.currency',
    amount: 'late_cancellation.amount',
    description: 'late_cancellation.description',
  },
};

const eventTypeOptions = [
  { label: 'eventTypePrivate', value: BookingStatuses.EventPrivate },
  { label: 'eventTypeWedding', value: BookingStatuses.EventWedding },
  { label: 'eventTypeBusiness', value: BookingStatuses.EventBusiness },
  { label: 'eventTypeCatering', value: BookingStatuses.EventCatering },
];

export {
  bookingModalConfig,
  bookingStatuses,
  bookingFormFieldNames,
  bookingGroups,
  eventTypeOptions,
};

export enum BookingModalViews {
  Default = 'default',
  Tables = 'tables',
}

export enum BookingModalLayouts {
  Tables = 'tables',
  FloorPLan = 'floor_plan',
}

export enum AvailableTablesViews {
  Full = 'full',
  Simplified = 'simplified',
}

export const getReservationValidationSchema = (
  lfcMinAmount: number,
  lcfMaxAmount: number,
  lcfMaxDescriptionLength: number,
  lfcCurrency?: string,
) => {
  const { t } = i18n;

  return {
    guests: yup.number().when('status', {
      is: (status) => status !== Statuses.Blocked,
      then: yup.number().min(1, t('bookingModalMinGuests')),
    }),
    start_time: yup.number().min(1, t('bookingModalStartTimeIsRequired')),
    end_time: yup
      .number()
      .test('test-endDate', t('bookingModalStartTimeBeforeEndTime'), function (end_time) {
        const { start_time } = this.parent;
        return end_time > start_time;
      })
      .required(t('bookingModalEndTimeIsRequired')),
    tables: yup.array().of(yup.number()).min(1, t('bookingModalTableIsRequired')),
    status: yup.string().oneOf(Object.values(Statuses)),
    client: yup
      .object()
      .shape({
        name: yup.string().required(t('bookingModalGuestNameIsRequired')),
        phone: yup.string().when('$phone_required', {
          is: true,
          then: yup
            .string()
            .required(t('bookingModalGuestPhoneIsRequired'))
            .test('phone-number-test', t('invalidPhoneNumber'), phoneNumberIsValid),
          otherwise: yup.string(),
        }),
      })
      .when('status', (status, schema) => {
        return status === Statuses.Blocked || status === Statuses.WalkIn
          ? schema.strip(true)
          : schema;
      }),
    late_cancellation: yup
      .object()
      .nullable()
      .when(['$isLateCancellation', 'status'], {
        is: (isLateCancellation, status) =>
          isLateCancellation && (status === Statuses.NoShow || status === Statuses.Cancelled),
        then: yup.object({
          amount: yup
            .number()
            .typeError(t('chargeAmountIsRequired'))
            .required(t('chargeAmountIsRequired'))
            .min(
              lfcMinAmount,
              t('chargeMinimumAmountExceeded', { minAmount: lfcMinAmount, currency: lfcCurrency }),
            )
            .max(
              lcfMaxAmount,
              t('chargeMaximumAmountExceeded', { maxAmount: lcfMaxAmount, currency: lfcCurrency }),
            ),
          description: yup
            .string()
            .nullable()
            .max(
              lcfMaxDescriptionLength,
              t('chargeDescriptionTooLong', { maxDescriptionLength: lcfMaxDescriptionLength }),
            ),
        }),
        otherwise: yup.object().strip(true),
      }),
  };
};
