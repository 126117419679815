import { Origin } from 'types/reservation';
import { ReactComponent as OnlineIcon } from 'images/icons/Badges/ic-online.svg';
import { ReactComponent as PhoneIcon } from 'images/icons/Badges/ic-phone.svg';

interface OwnProps {
  origin: Origin;
}

const OriginIcon = ({ origin }: OwnProps) => {
  switch (origin) {
    case Origin.Phone:
      return <PhoneIcon width={24} height={24} />;
    default: {
      return <OnlineIcon width={24} height={24} />;
    }
  }
};

export default OriginIcon;
