import { Stack, Typography } from '@mui/material';
import { TurnoverLabel } from 'app/components/NewBookingModal/_components/DateAndTime/_components/TurnoverLabel';
import { RotatingArrow } from 'app/components/StyledComponents/RotatingArrow';
import { ReactComponent as ClockIcon } from 'images/icons/ic-clock.svg';
import { useTranslation } from 'react-i18next';

type Props = {
  isOpen: boolean;
  placeholder?: string;
};

export const TimeSelectRenderValue = ({ isOpen, placeholder }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={'space-between'}
        spacing={1}
        width={'100%'}
        sx={{
          minHeight: '41px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          pr: 3,
        }}
      >
        <Typography>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            gap={1}
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            <ClockIcon />
            {placeholder}
          </Stack>
        </Typography>
      </Stack>
      <RotatingArrow
        open={isOpen}
        sx={{
          position: 'absolute',
          top: '8px',
          right: '12px',
        }}
        isMargin={false}
      />
    </>
  );
};
