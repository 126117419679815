import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading } from 'app/components/Heading';
import { CustomCard } from 'app/components/NewBookingModal/_components/CustomCard';
import { Divider, Grid, LinearProgress } from '@mui/material';
import clientActions from 'redux/actions/app/clients';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectClientStatsById } from 'redux/selectors/client';
import { StatisticItem } from 'app/components/NewBookingModal/_components/GuestSection/_components/GuestHistory/_components/StatisticItem';

type Props = {
  id: number;
};
export const GuestHistory = ({ id }: Props) => {
  const { t } = useTranslation();
  const stats = useAppSelector(selectClientStatsById(id));
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    dispatch(clientActions.getOne(id)).finally(() => setIsLoading(false));
  }, [id]);

  if (isLoading && !stats) {
    return <LinearProgress />;
  }

  if (!stats) {
    return null;
  }

  return (
    <CustomCard>
      <Heading>{t('guestStats')}</Heading>
      <Grid container spacing={2}>
        {Object.entries(stats.reservation).map(([key, value]) => (
          <StatisticItem key={key} label={key} value={value} />
        ))}
      </Grid>
      {stats.feedback.total > 0 && (
        <>
          <Divider sx={{ my: 2 }} />
          <Grid container spacing={2}>
            {Object.entries(stats.feedback).map(([key, value]) => (
              <StatisticItem key={key} label={key} value={value} />
            ))}
          </Grid>
        </>
      )}
    </CustomCard>
  );
};
