import { ReactNode } from 'react';
import { Box, SxProps, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { StyledLabel } from 'app/components/NewBookingModal/_components/StyledLabel';
import { MainHeading } from 'app/components/TextElements/MainHeading';

type Status = {
  [key: string]: string;
};

interface OwnProps {
  name: string;
  children: ReactNode;
  header?: ReactNode;
  sx?: SxProps;
  headerWrapperSx?: SxProps;
  titleAndHeaderWrapperSx?: object;
  statuses?: Status[];
  additionalText?: ReactNode;
  additionalElements?: ReactNode;
  sectionDisabled?: boolean;
}

export const BookingModalSection = ({
  name,
  children,
  header,
  statuses,
  additionalText,
  sx,
  additionalElements,
  sectionDisabled = false,
  headerWrapperSx,
  titleAndHeaderWrapperSx,
}: OwnProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        padding: { xs: '10px 16px 16px 16px', xl: theme.paddings.bookingModal },
        borderRadius: theme.borderRadius.small,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'brandWhite',
        height: '100%',
        width: '100%',
        position: 'relative',
        ...sx,
      }}
    >
      {sectionDisabled && (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            backdropFilter: 'blur(3px)',
            zIndex: 2,
            cursor: 'not-allowed',
            borderRadius: theme.borderRadius.default,
          }}
        />
      )}
      <Box>
        <Box
          sx={{
            marginBottom: '24px',
            display: 'flex',
            gap: '8px',
            flexWrap: 'wrap',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              width: '100%',
              gap: '8px 24px',
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <Box
                sx={{
                  display: 'flex',
                  columnGap: '8px',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                  width: '100%',
                  ...titleAndHeaderWrapperSx,
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <MainHeading
                    sx={{
                      wordBreak: 'break-word',
                    }}
                  >
                    {name}
                  </MainHeading>
                  {additionalElements}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: { xs: 'flex-start', md: 'flex-end' },
                    gap: '8px',
                    ...headerWrapperSx,
                  }}
                >
                  {header}
                  {statuses && (
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '8px',
                        flexWrap: 'wrap',
                      }}
                    >
                      {statuses.map(({ name, status }) => (
                        <StyledLabel name={name} key={status} status={status} />
                      ))}
                    </Box>
                  )}
                </Box>
              </Box>
              {additionalText && (
                <Typography
                  sx={{
                    fontSize: '13px',
                    color: 'comet',
                    marginTop: '8px',
                    wordBreak: 'break-word',
                  }}
                >
                  {additionalText}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
        <Box>{children}</Box>
      </Box>
    </Box>
  );
};
