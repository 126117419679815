import { useState, useEffect, useCallback, MutableRefObject } from 'react';
import { BookingModalSection } from 'app/components/NewBookingModal/_components/BookingModalSection';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import { CustomNarrowButton } from 'app/components/Buttons/CustomNarrowButton';
import { Heading } from 'app/components/Heading';
import { SearchAutocomplete } from 'app/components/FormElements/SearchAutocomplete';
import { MuiPhoneInput } from 'app/components/FormElements/MuiPhoneInput';
import { ControlledInput } from 'app/components/FormElements/ControlledInput';
import { ReactComponent as UserIcon } from 'images/icons/ic-user-name.svg';
import { ReactComponent as MailIcon } from 'images/icons/ic-mail-outlined.svg';
import { ReactComponent as CompanyIcon } from 'images/icons/ic-building-outlined.svg';
import { ReactComponent as ArrowIcon } from 'images/icons/ic-arrow-narrow-right.svg';
import { MuiCheckbox } from 'app/components/FormElements/MuiCheckbox';
import { CustomCard } from 'app/components/NewBookingModal/_components/CustomCard';
import { TagSelector } from 'app/components/FormElements/TagSelector';
import { ReservationClientTags, Statuses } from 'types/reservation';
import { bookingModalConfig } from 'app/components/NewBookingModal/_config';
import { additionalInfo } from 'app/components/NewBookingModal/_components/GuestSection/_config';
import { bookingFormFieldNames } from 'app/components/NewBookingModal/_config';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { debounce } from 'lodash';
import { searchClientCall } from 'api/app/restaurant/reservation/search';
import { IReservationAdditionalInfo, IReservationClient } from 'types/app/reservations';
import { muiInputBaseRoot } from 'styles/constants/inputStyles';
import { snakeToCamelCase } from 'utils/str/snakeToCamelCase';
import { useStatus } from 'hooks/useStatus';
import { ReactComponent as GuestTagIcon } from 'images/icons/Badges/ic-tag.svg';
import { MuiTextarea } from 'app/components/FormElements/MuiTextarea';
import { joinWithSeparator } from 'utils/str/joinWithSeparator';
import { useFormContext } from 'react-hook-form';
import { GuestHistory } from 'app/components/NewBookingModal/_components/GuestSection/_components/GuestHistory';
import bookingActions from 'redux/actions/app/booking';
import { CountryCode } from 'libphonenumber-js';

interface OwnProps {
  selectedStatus: Statuses;
  guestNameRef: MutableRefObject<any>;
  guestPhoneRef: MutableRefObject<any>;
}

interface IOption {
  label: string;
  value: IReservationClient;
}

const rowToColumnStyles = {
  display: 'flex',
  gap: '8px',
  flexDirection: { xs: 'column', md: 'row' },
};

export const GuestSection = ({ selectedStatus, guestNameRef, guestPhoneRef }: OwnProps) => {
  const booking = useAppSelector((state) => state.app.booking);
  const dispatch = useAppDispatch();
  const { client_tags } = useAppSelector((state) => state.app.config);
  const { isReadOnly } = useStatus();
  const { setValue, watch } = useFormContext();
  const restaurantCountry = useAppSelector(
    (state) => state.app.settings.restaurant.address?.country,
  );
  const clientFormValues = watch(bookingFormFieldNames.client.tags);
  const clientAdditionalInfo = booking.client?.additional_info;
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<IOption[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const { showGuestDetails } = bookingModalConfig(selectedStatus);
  const generateVisibleInputsState = useCallback(
    (additionalInfo: IReservationAdditionalInfo | undefined) => ({
      [bookingFormFieldNames.client.additional_info.birthday]: !!additionalInfo?.birthday,
      [bookingFormFieldNames.client.additional_info.family_member]: !!additionalInfo?.family_member,
      [bookingFormFieldNames.client.additional_info.favourites]: !!additionalInfo?.favourites,
      [bookingFormFieldNames.client.additional_info.description]: !!additionalInfo?.description,
    }),
    [],
  );

  const [visibleInputs, setVisibleInputs] = useState(() =>
    generateVisibleInputsState(clientAdditionalInfo),
  );

  const { t } = useTranslation();

  useEffect(() => {
    setVisibleInputs(generateVisibleInputsState(clientAdditionalInfo));
  }, [clientAdditionalInfo]);

  const toggleInputVisibility = (name: any) => {
    setVisibleInputs((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const searchClients = useCallback(
    debounce(async (query) => {
      if (query.length < 2) {
        setOptions([]);
        setLoading(false);
        return;
      }
      setLoading(true);
      try {
        const response = await searchClientCall({ query, limit: 20 });
        setOptions(
          response.map((client) => ({
            label: joinWithSeparator([client?.name, client?.phone, client?.email, client?.company]),
            value: client,
          })),
        );
      } catch (error) {
        setOptions([]);
      }
      setLoading(false);
    }, 500),
    [],
  );

  useEffect(() => {
    if (searchValue) {
      searchClients(searchValue);
    }
  }, [searchValue]);

  const handleClientSelect = (client: IReservationClient) => {
    dispatch(bookingActions.updateReservationClient(client));
    const { name, email, company, phone, note, tags, subscribed, additional_info } = client;
    const clientData = {
      [bookingFormFieldNames.client.name]: name,
      [bookingFormFieldNames.client.email]: email,
      [bookingFormFieldNames.client.company]: company,
      [bookingFormFieldNames.client.phone]: phone,
      [bookingFormFieldNames.client.note]: note,
      [bookingFormFieldNames.client.subscribed]: subscribed,
      [bookingFormFieldNames.client.tags]: tags,
      [bookingFormFieldNames.client.additional_info.description]: additional_info?.description,
      [bookingFormFieldNames.client.additional_info.family_member]: additional_info?.family_member,
      [bookingFormFieldNames.client.additional_info.favourites]: additional_info?.favourites,
      [bookingFormFieldNames.client.additional_info.short_info]: additional_info?.short_info,
    };

    Object.entries(clientData).forEach(([key, value]) => {
      setValue(key, value);
    });
  };

  const statuses = [
    ReservationClientTags.Vip,
    ReservationClientTags.BigSpender,
    ReservationClientTags.BlackListed,
  ]
    .filter((tag) => clientFormValues.includes(tag))
    .map((tag) => ({
      name: t(snakeToCamelCase(tag)),
      status: tag.toLowerCase(),
    }));

  return (
    <BookingModalSection
      name={t('bookingModalGuestTitle')}
      statuses={statuses}
      sectionDisabled={!showGuestDetails}
      sx={{
        flexGrow: 1,
        flexShrink: 2,
        flexBasis: '50%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
        }}
      >
        <Box>
          <Box
            sx={{
              display: 'flex',
              columnGap: '8px',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              flexWrap: 'wrap',
            }}
          >
            <Heading>{t('guestInformation')}</Heading>
            <CustomNarrowButton
              variant="text"
              color="secondary"
              onClick={() => {}}
              name={t('openInGuestBook')}
              endIcon={<ArrowIcon />}
              disabled
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <SearchAutocomplete
              value={searchValue}
              options={options}
              onOptionSelected={handleClientSelect}
              onInputChange={(data) => setSearchValue(data)}
              isLoading={loading}
              disabled={isReadOnly}
            />
            <Box sx={rowToColumnStyles}>
              <ControlledInput
                name={bookingFormFieldNames.client.name}
                placeholder={t('guestName')}
                icon={<UserIcon />}
                inputRef={guestNameRef}
                disabled={isReadOnly}
              />
              <MuiPhoneInput
                defaultCountry={restaurantCountry as CountryCode}
                name={bookingFormFieldNames.client.phone}
                inputRef={guestPhoneRef}
                disabled={isReadOnly}
              />
            </Box>
            <Box sx={rowToColumnStyles}>
              <ControlledInput
                name={bookingFormFieldNames.client.email}
                type="email"
                placeholder={t('email')}
                icon={<MailIcon />}
                disabled={isReadOnly}
              />
              <ControlledInput
                name={bookingFormFieldNames.client.company}
                placeholder={t('company')}
                icon={<CompanyIcon />}
                disabled={isReadOnly}
              />
            </Box>
            <MuiCheckbox
              name={bookingFormFieldNames.client.subscribed}
              disabled={true}
              label={t('newslettersLabel')}
            />
          </Box>
        </Box>
        <CustomCard sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <MuiTextarea
            name={bookingFormFieldNames.client.note}
            placeholder={t('guestNotePlaceholder')}
            label={t('guestNoteLabel')}
            sx={{
              backgroundColor: 'brandWhiteDark',
            }}
            icon={
              <Box sx={{ width: '16px', height: '16px' }}>
                <GuestTagIcon />
              </Box>
            }
          />
          <TagSelector
            name={bookingFormFieldNames.client.tags}
            label={t('clientsInformation')}
            tags={client_tags}
            tagsWrapperSx={{
              maxWidth: '536px',
            }}
            rolledWrapperSx={{
              paddingBottom: 0,
            }}
          />
          {/*TODO remove in future*/}
          <ControlledInput
            name={bookingFormFieldNames.client.additional_info.short_info}
            placeholder={t('generalInformation')}
            sxFormControl={{
              display: 'none',
            }}
            sx={muiInputBaseRoot}
          />
          <Box sx={{ display: 'flex', gap: '16px', flexDirection: 'column' }}>
            <Box>
              <Heading>{t('additionalInformation')}</Heading>
              <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                {additionalInfo.map((info) => {
                  return (
                    <Button
                      key={info.name}
                      variant={visibleInputs[info.name] ? 'contained' : 'text'}
                      color="secondary"
                      onClick={() => toggleInputVisibility(info.name)}
                      sx={{
                        padding: '1.5px 8px',
                        fontSize: '12px',
                        fontWeight: 500,
                        color: visibleInputs[info.name] ? 'white' : 'secondary',
                      }}
                    >
                      {t(info.label)}
                    </Button>
                  );
                })}
              </Box>
            </Box>
            {additionalInfo.map((info) => {
              if (visibleInputs[info.name]) {
                if (info.name === bookingFormFieldNames.client.additional_info.birthday) {
                  return null;
                  // <NewMuiDatePicker
                  //   key={info.name}
                  //   id="birthday-picker"
                  //   value={watch(info.name)}
                  //   label={t(info.label)}
                  //   onChange={(date) => setValue(info.name, date)}
                  //   sx={{
                  //     '& .MuiInputBase-root': {
                  //       backgroundColor: 'brandWhite',
                  //     },
                  //   }}
                  // />
                } else {
                  return (
                    <ControlledInput
                      icon={info.icon}
                      key={info.name}
                      name={info.name}
                      placeholder={t(info.label)}
                      sx={muiInputBaseRoot}
                      disabled={isReadOnly}
                    />
                  );
                }
              }
              return null;
            })}
          </Box>
        </CustomCard>
        {booking?.client?.id && <GuestHistory id={booking.client.id} />}
      </Box>
    </BookingModalSection>
  );
};
